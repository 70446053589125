//
// Content
//

// Desktop Mode
@include media-breakpoint-up(lg) {
  .content {
    padding: get($page-padding, desktop) 0;

    // Fixed Footer Mode
    .footer-fixed & {
      padding-bottom: get($footer-config, fixed, height);
    }
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  .content {
    padding: get($page-padding, tablet-and-mobile) 0;

    // Subheader Enabled & Subheader Transparent Modes
    .subheader-enabled.subheader-transparent & {
      padding-top: 0;
    }
  }
}

.table-ProgressBar {
  font-size: 14px;

  .progress {
    height: 5px;
  }
}

.slider-thumb {
  height: 45px;
  width: 90px;
  object-fit: cover;
}

.font-14 {
  font-size: 14px !important;
}

.btn-group-toggle {
  .btn-secondary:not(.toggle-no) {
    &.active {
      background-color: $primary !important;
      color: $white !important;
      border-color: $primary !important;
    }
  }
  .btn-secondary.toggle-no {
    &.active {
      background-color: $gray-600 !important;
      color: $white !important;
      border-color: $gray-600 !important;
    }
  }
}

.min-w-110 {
  min-width: 110px;
}

.progress-h-10 {
  height: 10px;
}
